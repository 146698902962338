<template>
  <div>
    <h1 class="my-5">Черный список</h1>
    <div class="hovering__pad p-2">
      <div class="d-flex align-items-center justify-content-between">
        <b-form class="py-2" inline
          ><b-form-group class="mr-4"
            ><b-input v-model="filterParams.instaname" placeholder="Поиск"></b-input></b-form-group
          ><b-form-group class="mr-4">
            <b-checkbox v-model="filterParams.is_banned"
              >Только заблокированые</b-checkbox
            > </b-form-group
          ><b-form-group
            ><b-button @click="updateData" variant="yellow">Найти</b-button></b-form-group
          ></b-form
        >
        <b-button
          @click="
            () => {
              detailitem = lodash.cloneDeep(model);
            }
          "
          class="d-inline-block"
          variant="primary"
          >Добавить</b-button
        >
      </div>
      <b-table
        @row-selected="
          (val) => {
            detailitem = lodash.cloneDeep(val[0]);
          }
        "
        ref="selectableTable"
        selectable
        select-mode="single"
        class="mt-2"
        :fields="fields"
        :items="items"
        stacked="sm"
        responsive
      >
        <template #cell(screenshots)="data">
          <b-button
            v-if="!!data.value.length"
            @click="viewscreens = data.value"
            variant="outline-default"
            >Показать</b-button
          >
        </template>
      </b-table>
    </div>
    <b-modal
      v-if="!!detailitem"
      :title="
        !!detailitem && detailitem.id ? 'Редактирование' : 'Добавить пользователя в черный список'
      "
      @ok="manageBan"
      :cancel-title="'Закрыть'"
      :ok-title="'Подтвердить'"
      @hidden="clearSelected"
      :visible="!!detailitem"
      ><b-form
        ><b-form-group label="Instagram:"
          ><b-input v-model="detailitem.instaname"></b-input></b-form-group
        ><b-form-group label="User ID:"><b-input v-model="detailitem.user"></b-input></b-form-group
        ><b-form-group label="Штрафные очки:"
          ><b-input v-model="detailitem.penalty_points"></b-input></b-form-group
        ><b-form-group label="Навсегда:"
          ><b-form-checkbox v-model="detailitem.forever"></b-form-checkbox></b-form-group
        ><b-form-group label="Дата блокировки:"
          ><b-input v-model="detailitem.banned_date"></b-input></b-form-group
        ><b-form-group label="Заблокирован:"
          ><b-form-checkbox v-model="detailitem.is_banned"></b-form-checkbox></b-form-group
        ><b-form-group label="Причина:"
          ><b-input v-model="detailitem.reason"></b-input></b-form-group></b-form
      ><b-form-group>
        <screenshot-loader
          v-model="detailitem.screenshots"
          uploadUrl="/api/reviews/reviewmemberban-upload-screenshot/"
        ></screenshot-loader></b-form-group
    ></b-modal>
    <b-modal :visible="!!viewscreens.length" v-if="!!viewscreens.length">
      <b-row>
        <b-col>
          <b-carousel
            v-model="currentSlide"
            controls
            :interval="0"
            indicators
            :ride="false"
            background="#ababab"
            style="text-shadow: 1px 1px 2px #333"
          >
            <b-carousel-slide v-for="screen in viewscreens" :key="screen.id">
              <template #img>
                <img
                  class="d-block img-fluid m-auto"
                  width="540"
                  :src="screen.image"
                  alt="image slot"
                />
              </template>
            </b-carousel-slide>
          </b-carousel>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import reviews from '@main/api/reviews';
import ScreenshotLoader from '../reusable/forms/ScreenshotLoader.vue';

export default {
  components: { ScreenshotLoader },
  data: () => ({
    items: null,
    detailitem: null,
    viewscreens: [],
    currentSlide: 1,
    model: {
      instaname: '',
      penalty_points: 0,
      forever: false,
      is_banned: true,
      banned_date: null,
      reason: '',
      screenshots: [],
    },
    filterParams: {
      is_banned: null,
      instaname: null,
    },
  }),
  computed: {
    fields() {
      return [
        { key: 'instaname', label: 'Аккаунт' },
        {
          key: 'is_banned',
          label: 'Забанен',
          formatter(val, key, item) {
            return val ? 'Да' : 'Нет';
          },
        },
        {
          key: 'forever',
          label: 'Навсегда',
          formatter(val, key, item) {
            return val ? 'Да' : 'Нет';
          },
        },
        { key: 'penalty_points', label: 'Штрафные баллы' },
        { key: 'was_banned', label: 'Сколько раз был в бане' },
        { key: 'edited_by', label: 'Кто занес' },
        { key: 'reason', label: 'Причина', tdClass: 'mw-250' },
        { key: 'screenshots', label: 'Скриншоты' },
      ];
    },
  },
  methods: {
    updateData() {
      reviews.bans.list(this.filterParams).then((response) => {
        this.items = response.results;
      });
    },
    clearSelected() {
      this.detailitem = null;
      this.$refs.selectableTable.clearSelected();
    },
    manageBan() {
      let method;
      if (this.detailitem.id) {
        method = reviews.bans.update;
      } else {
        method = reviews.bans.create;
      }
      method(this.detailitem).then(() => {
        this.updateData();
      });
    },
  },
  watch: {
    detailitem(val) {
      console.log(val);
    },
    'filterParams.is_banned': {
      handler(val) {
        this.updateData();
      },
    },
  },
  mounted() {
    this.updateData();
  },
};
</script>

<style lang="scss" scoped></style>
